import Request from './request'
import state from '../store/state'

const PATH = {
  TAGGED_PRIORITY: '/taggedPriority'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getTaggedPriorities = ({ serviceId }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  let url = `${state.config.konecta}${PATH.TAGGED_PRIORITY}`
  if (serviceId) {
    url += `?where[service]=${serviceId}`
  }

  return Request.get(url, config)
}

const createTaggedPriority = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${state.config.konecta}${PATH.TAGGED_PRIORITY}`,
    payload,
    config
  )
}

const updateTaggedPriority = (taggedPriorityId, payload) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    `${state.config.konecta}${PATH.TAGGED_PRIORITY}/${taggedPriorityId}`,
    payload,
    config
  )
}

const deleteTaggedPriority = taggedPriorityId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    `${state.config.konecta}${PATH.TAGGED_PRIORITY}/${taggedPriorityId}`,
    config
  )
}

export default {
  getTaggedPriorities,
  createTaggedPriority,
  updateTaggedPriority,
  deleteTaggedPriority
}
